import React, { useEffect, useState } from "react"
import axios from "axios"
import Cookies from "js-cookie"
import moment from "moment"
import { useStaticQuery, graphql } from "gatsby"
import Button from "../../../components/template-partials/global-components/buttons"
import HubSpotForm from '..//hubspot-form';

const ContactForm = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [npi, setNPI] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [connectTo, setConnectTo] = useState("")
  const [formReady, setFormReady] = useState(false)
  const [submitted, setSubmitted] = useState("")

  const client = useStaticQuery(graphql`
    query ClientMetaQueryTwo {
      site {
        siteMetadata {
          stgHCPClientID
          stgHCPClientSecret
          stgHCPEdk
          prdHCPClientID
          prdHCPClientSecret
          prdHCPEdk
        }
      }
    }
  `)

  const getIP = () => {
    axios
      .get("https://ipinfo.io", {
        params: {
          format: "jsonp",
        },
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error("Error fetching data:", error)
      })
  }

  //On Success
  const submitForm = () => {
    let qs = new URL(document.location.toString()).searchParams

    let utm_campaign = qs.get("utm_campaign")
    let utm_medium = qs.get("utm_medium")
    let utm_source = qs.get("utm_source")

    let params = JSON.stringify({
      table: "recorlev_sign_up",
      first_name: firstName,
      last_name: lastName,
      email: email,
      zip: zipCode,
      npi_number: npi,
      please_connect_me_to: connectTo,
      ip_address: getIP(),
      user_agent: window.navigator.userAgent,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
    })

    axios
      .post(
        `https://xeris-form-collection-aad995a20c88.herokuapp.com/collections/insert`,
        params,
        {
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      )
      .then(function (response) {
        //On success, store the token and timestamp
        setSubmitted(true)
        //FIRE A GTM EVENT HERE
      })
      .catch(function (error) {
        console.log(error)
        console.log(
          "an error was detected in submitting the token or payload for this property: contact-form.js"
        )
        setFormReady(false)
        // setApiError(true);
      })
  }

  useEffect(() => {
    if (submitted === true) {
      if (typeof window !== "undefined") {
        window.dataLayer.push({
          event: "form-submit-success-HCP",
        })
      }
    }
  }, [submitted])

  //Show input error messages
  function showError(input, message) {
    let formControl = document.getElementById(input).parentElement.parentElement
    formControl.className = "form-control error"

    let small = document.getElementById(input).nextElementSibling
    small.innerText = message
    //console.log(small);
  }

  function validateEmail(elementValue) {
    let emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return emailPattern.test(elementValue)
  }

  function validateZipCode(elementValue) {
    let zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/
    return zipCodePattern.test(elementValue)
  }

  function validateNPI(elementValue) {
    let npiPattern = /[^0-9a-zA-Z]/g

    if (elementValue !== "") {
      return npiPattern.test(elementValue)
    }
  }

  function validateInput(elementValue) {
    let pattern = /[a-zA-Z]+/g

    if (elementValue === "") {
      return false
    } else {
      return pattern.test(elementValue)
    }
  }

  function checkNPI(element) {
    let x = element.value.replace(/\D/g, '').slice(0, 10);
    element.value = x;
  }

  //checkRequired fields
  function checkRequired(inputArr) {
    var elems = document.querySelectorAll(".form-control")

    ;[].forEach.call(elems, function (el) {
      el.classList.remove("error")
    })

    if (!validateInput(firstName)) {
      let target = "firstName"
      let message = "Enter a valid first name."
      showError(target, message)
    }

    if (!validateInput(lastName)) {
      let target = "lastName"
      let message = "Enter a valid last name."
      showError(target, message)
    }

    if (!validateEmail(email)) {
      let target = "email"
      let message = "Enter a valid email."
      showError(target, message)
    }

    if (!validateZipCode(zipCode)) {
      let target = "zipCode"
      let message = "Enter a valid zip code. Ex: (12345 or 12345-6789)."
      showError(target, message)
    }

    if (validateNPI(npi)) {
      let target = "npi"
      let message = "Enter a valid NPI code. No special characters."
      showError(target, message)
    }

    if (!validateInput(connectTo)) {
      let target = "connectTo"
      let message = "Please select one."
      showError(target, message)
    }

    let formComplete = true
    ;[].forEach.call(elems, function (el) {
      if (el.classList.contains("error")) {
        formComplete = false
      }
    })

    if (formComplete === true) {
      submitForm()
      // let envData = checkEnv();
      // if (Cookies.get(envData.cookie_name)) {
      //   console.log('measuring token to see expire');
      //   measureToken(true);
      // } else {
      //   setFormReady(false);
      //   setApiError(true);
      // }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    checkRequired()
  }

  return (
    <>
      <p>
        To learn more about Recorlev<sup>&reg;</sup>, please complete the
        form below and we will make sure that you are connected with the
        right support as soon as possible.
      </p>
      <p>All fields required unless otherwise noted.</p>

      <div className="row">
          <div className="columns small-12 large-4">
            <HubSpotForm portalId="45850190" formId="8f44e9fc-9322-4a8e-b55f-f11156ae7bd3" />
          </div>
      </div>

      <div className="row">
          <div className="columns small-12 large-6">
            <p>
              By submitting this form, I give permission for a
              Xeris&nbsp;Pharmaceuticals<sup>&reg;</sup> representative to
              contact me by the following methods, but not limited to:
              phone, email, or text message. I understand I am giving
              Xeris&nbsp;Pharmaceuticals,&nbsp;Inc., its affiliates, and
              business partners permission to use the personal information
              provided in this registration form to contact me by the
              following methods, but not limited to, mail, email, telephone
              call or in-person about disease and product information,
              disease or product-related events, support services, market
              research, and to share promotional and marketing information.
              By submitting this form, I consent to these uses and am
              confirming that I have read and agree to the
              Xeris&nbsp;Pharmaceuticals{" "}
              <a
                href="https://xerispharma.com/terms-use"
                className="text-underline gtm-link-external"
                target="_blank"
                rel="noreferrer"
                data-gtm-event-label="Terms of Use"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://www.xerispharma.com/privacy-policy"
                className="text-underline gtm-link-external"
                target="_blank"
                rel="noreferrer"
                data-gtm-event-label="Privacy Policy"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
      </div>
    </>
  )
}

export default ContactForm
