import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import Button from "../../components/template-partials/global-components/buttons"

const MOA = () => (
  <Layout>
    <Seo
      title="Mechanism of Action | RECORLEV® (levoketoconazole)"
      description="Learn about the key adrenal steroidogenesis enzymes that are inhibited by the ingredients in Recorlev."
      ogdescription="Learn about the key adrenal steroidogenesis enzymes that are inhibited by the ingredients in Recorlev. See Important Safety Information, including BOXED WARNING."
      siteType="hcp"
    />
    <InteriorPageHeader alt="MOA">
      <h1>
        Recorlev<sup>&reg;</sup> is a potent inhibitor of key adrenal
        steroidogenesis enzymes
      </h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div className="row">
        <div className="small-12 column">
          <h2>
            Recorlev inhibits 3 key enzymes involved in the synthesis of cortisol and testosterone at lower concentrations than its racemic counterpart, as seen in preclinical studies<sup>1-4</sup>
          </h2>

          <div className="text-center">
            <StaticImage
              src="../../images/recorlev-mechanism-of-action-diagram.png"
              className="show-for-large"
              alt="Recorlev® is a potent inhibitor of key adrenal steroidogenesis enzymes CYP11A1, CYP17A1, CYP11B1"
              placeholder="blurred"
              layout="constrained"
              width={3840}
            />

            <StaticImage
              src="../../images/recorlev-mechanism-of-action-diagram-mobile.png"
              className="hide-for-large mt-2"
              alt="Recorlev® is a potent inhibitor of key adrenal steroidogenesis enzymes CYP11A1, CYP17A1, CYP11B1"
              placeholder="blurred"
              layout="constrained"
              width={497}
            />
          </div>
        </div>
      </div>

      <div className="row py-3 lg_pb-3">
        <div className="small-12 column text-center">
          <StaticImage
            src="../../images/moa-levoketoconazole.png"
            alt="levoketoconazole"
            placeholder="blurred"
            layout="constrained"
            width={466}
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <p className="footnote">MOA=mechanism of action.</p>
          <p className="footnote">
            <b>References: 1.</b> Recorlev [prescribing information]. Chicago, IL; Xeris Pharmaceuticals, Inc. <b>2.</b> Creemers SG, Feelders RA, de
            Jong FH, et al. Levoketoconazole, the 2S,4R enantiomer of
            ketoconazole, a new steroidogenesis inhibitor for Cushing’s syndrome
            treatment. <i>J Clin Endocrinol Metab.</i> 2021;106(4):e1618-e1630.
            doi:10.1210/clinem/dgaa989. <b>3.</b> Fleseriu M, Auchus RJ, Pivonello R, Salvatori R, Zacharieva S, Biller BMK. Levoketoconazole: a novel treatment for endogenous Cushing’s syndrome. <i>Expert Rev Endocrinol Metab</i>.2021;16(4):159-174. <b>4.</b> Auchus RJ, Wu Y, Liu J, Peng HM. 2S,4R-ketoconazole is the relevant enantiomer of ketoconazole for cortisol synthesis inhibition:
steroidogenic P450s inhibition involves multiple mechanisms. Poster presented at: the Endocrine Society 100th Annual Meeting; March 17-20, 2018; Chicago, IL.{" "}
          </p>
        </div>
      </div>

      <div className="row py-2 lg_pt-1 lg_pb-3">
        <div className="column text-center">
          <Button
            ButtonLink={"/hcp/support/xeris-careconnection/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={"Complete support for you and your patients"}
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"Complete support for you and your patients"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default MOA
