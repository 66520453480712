import React, { useState, useEffect } from "react"

import { InView } from "react-intersection-observer"
import Cookies from "js-cookie"

import { isHCP } from "../../../helpers/helpers"

import { hcpDetails } from "../isi/hcp-isi"
import { patientDetails } from "../isi/patient-isi"

const Isi = () => {
  const [isiText, setIsiText] = useState("")
  const [isiState, setIsiState] = useState("closed")
  const [cookieText, setCookieText] = useState("")

  Cookies.set("name", "value")

  const [isVisible, setIsVisible] = useState("show-cookie")

  useEffect(() => {
    if (isHCP()) {
      setIsiText(hcpDetails.hcpIsiSummary + hcpDetails.hcpIsiFull)
      setCookieText(hcpDetails.hcpCookie)
    } else {
      setIsiText(
        patientDetails.patientIsiSummary + patientDetails.patientIsiFull
      )
      setCookieText(patientDetails.patientCookie)
    }

    const hideDiv = localStorage.getItem("cookieNotification")
    if (hideDiv) {
      setIsVisible("hide-cookie")
    }
  }, [])

  const expand = () => {
    const body = document.body
    const container = document.getElementById('isi');

    if (container.classList.contains('open')) {
      setIsiState("closed")
      body.classList.remove("isi-open")
      container.classList.remove("open")
      container.classList.add("closed")
    } else {
      setIsiState("open")
      body.classList.add("isi-open")
      container.classList.add("open")
      container.classList.remove("closed")
    }
  }

  const cookieClose = () => {
    setIsVisible("hide-cookie")
    localStorage.setItem("cookieNotification", "hide-cookie")
  }

  return (
    <InView>
      {({ inView, ref, entry }) => (
        <section
          id="isi"
          className={isiState + " isi " + inView.toString() + " " + isVisible}
        >
          <div className="relative-isi" ref={ref}>
            <div
              className="row"
              dangerouslySetInnerHTML={{ __html: isiText }}
            ></div>
          </div>
          <div className="sticky-isi">
            <div id="cookieNotification" className={isVisible}>
              <div className="row">
                <div
                  className="columns small-10 medium-11"
                  dangerouslySetInnerHTML={{ __html: cookieText }}
                ></div>
                <div className="columns small-2 medium-1 text-right">
                  <button
                    aria-label="button"
                    className="close"
                    onClick={cookieClose}
                    onKeyDown={cookieClose}
                  >
                    TEST
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>

            <div id="sticky">
              <div className="row">
                <div className="columns text-right">
                  <button
                    aria-label="button"
                    className="expand-button gtm-isi"
                    data-gtm-event-label="ISI Expand"
                    onClick={expand}
                    onKeyDown={expand}
                  ></button>
                </div>
              </div>
              <div
                className="row"
                dangerouslySetInnerHTML={{ __html: isiText }}
              ></div>
            </div>
          </div>
        </section>
      )}
    </InView>
  )
}

export default Isi
