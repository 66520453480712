import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroPageHeader from "../components/template-partials/homepage/hero-page-header"
import headingBg from "../images/banner-placeholder.svg"
import Button from "../components/template-partials/global-components/buttons"

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Recorlev® (levoketoconazole) | Official Patient Site"
        description="Learn more about Recorlev, now approved by the FDA. Please see Important Safety Information and full Prescribing Information, including Boxed Warning, on this website."
        ogdescription="Learn more about Recorlev, now approved by the FDA. Please see Important Safety Information and full Prescribing
      Information, including Boxed Warning, on this website."
      />

      <HeroPageHeader backgroundImage={headingBg}></HeroPageHeader>
      <section className="home-buckets">
        <div className="row align-center text-center">
          <div className="columns small-12">
            <div className="row align-center">
              <div className="columns small-12 large-4 pb-2 lg_pb-0 large-text-left">
                <Button
                  ButtonLink={"/one-on-one-support/meet-your-support-team/"}
                  ButtonClass={
                    "primary align-middle h-100 md_py-1 lg_py-1 gtm-cta"
                  }
                  ButtonText={"SEE WHAT ONE-ON-ONE-SUPPORT CAN DO FOR YOU"}
                  ButtonIconAfter={"&nbsp;&gt;"}
                  EventLabel={"SEE WHAT ONE-ON-ONE-SUPPORT CAN DO FOR YOU"}
                ></Button>
              </div>
              <div className="columns small-12 large-4 pb-2 lg_pb-0">
                <Button
                  ButtonLink={"/what-is-recorlev/"}
                  ButtonClass={
                    "secondary align-middle h-100 md_py-1 lg_py-1 gtm-cta"
                  }
                  ButtonText={
                    "DISCOVER THE BENEFITS OF RECORLEV<SUP>&REG;</SUP> TREATMENT"
                  }
                  ButtonIconAfter={"&nbsp;&gt;"}
                  EventLabel={"DISCOVER THE BENEFITS OF RECORLEV® TREATMENT"}
                ></Button>
              </div>
              {/*
              <div className="columns small-12 large-4 pb-2 lg_pb-0 large-text-right">
                <Button
                  ButtonLink={'/taking-recorlev/'}
                  ButtonClass={'action align-middle h-100 md_py-1 lg_py-1 gtm-cta'}
                  ButtonText={'KNOW WHAT TO EXPECT WITH RECORLEV'}
                  ButtonIconAfter={'&nbsp;&gt;'}
                  EventLabel={'KNOW WHAT TO EXPECT WITH RECORLEV'}
                >
                </Button>
              </div>
              */}
            </div>
          </div>
        </div>
        <div></div>
      </section>
      {/* <button onClick={() => {
        throw new myUndefinedFunction();
      }}>Test error</button> */}
    </Layout>
  )
}
export default IndexPage
