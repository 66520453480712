import privacyPolicy from "../../../../static/Xeris-privacy-policy.pdf"

//Cookie
let hcpCookie =
  '<p>We use cookies to help personalize your experience. For more information on how we use cookies and similar technologies, and how you can change your settings, please see our <a href="https://www.xerispharma.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>'

//Summary
let hcpIsiSummary =
  '<div class="column small-12 isiText"><h1>Indication & Important Safety Information for Recorlev<sup>®</sup> (levoketoconazole)</h1>'

hcpIsiSummary +=
  '<h2 style="margin-bottom:1rem;">BOXED WARNING: HEPATOTOXICITY AND QT PROLONGATION</h2>'

hcpIsiSummary +=
  '<p><strong style="color: #00205b;">HEPATOTOXICITY</strong><br><strong>Cases of hepatotoxicity with fatal outcome or requiring liver transplantation have been reported with oral ketoconazole. Some patients had no obvious risk factors for liver disease. Recorlev is associated with serious hepatotoxicity. Evaluate liver enzymes prior to and during treatment.</strong></p></div>'

//Full Text

let hcpIsiFull =
  '<div class="column small-12 isiText"><p><strong style="color: #00205b;">QT PROLONGATION</strong><br><strong>Recorlev is associated with dose-related QT interval prolongation. QT interval prolongation may result in life-threatening ventricular dysrhythmias such as torsades de pointes. Perform ECG and correct hypokalemia and hypomagnesemia prior to and during treatment.</strong></p>'

hcpIsiFull +=
  '<p><strong style="color: #00205b;">INDICATION</strong><br>Recorlev is a cortisol synthesis inhibitor indicated for the treatment of endogenous hypercortisolemia in adult patients with Cushing’s syndrome for whom surgery is not an option or has not been curative.</p>'

hcpIsiFull += '<h3 style="color:#000000">Limitations of Use</h3>'

hcpIsiFull +=
  "<p>Recorlev is not approved for the treatment of fungal infections.</p>"


hcpIsiFull += "<h2>IMPORTANT SAFETY INFORMATION</h2>"

hcpIsiFull += "<h2>CONTRAINDICATIONS</h2>"

hcpIsiFull +=
  "<ul><li>Cirrhosis, acute liver disease or poorly controlled chronic liver disease, baseline AST or ALT > 3 times the upper limit of normal, recurrent symptomatic cholelithiasis, a prior history of drug induced liver injury due to ketoconazole or any azole antifungal therapy that required discontinuation of treatment, or extensive metastatic liver disease.</li><li>Taking drugs that cause QT prolongation associated with ventricular arrhythmias, including torsades de pointes.</li><li>Prolonged QTcF interval > 470 msec at baseline, history of torsades de pointes, ventricular tachycardia, ventricular fibrillation, or prolonged QT syndrome.</li><li>Known hypersensitivity to levoketoconazole, ketoconazole, or any excipient in Recorlev.</li><li>Taking certain drugs that are sensitive substrates of CYP3A4 or CYP3A4 and P-gp.</li></ul>"

hcpIsiFull += "<h2>WARNINGS AND PRECAUTIONS</h2>"

hcpIsiFull += '<h3 style="color:#000000">Hepatotoxicity</h3>'

hcpIsiFull +=
  '<p style="margin-bottom: 0rem;">Serious hepatotoxicity has been reported in patients receiving Recorlev, irrespective of the dosages used or the treatment duration. Drug-induced liver injury (peak ALT or AST greater than 3 times upper limit of normal) occurred in patients using Recorlev. Avoid concomitant use of Recorlev with hepatotoxic drugs. Advise patient to avoid excessive alcohol consumption while on treatment with Recorlev. Routinely monitor liver enzymes and bilirubin during treatment.</p>'

hcpIsiFull += '<h3 style="color:#000000">QT Prolongation</h3>'

hcpIsiFull +=
  '<p style="margin-bottom: 0rem;">Use Recorlev with caution in patients with other risk factors for QT prolongation, such as congestive heart failure, bradyarrhythmias, and uncorrected electrolyte abnormalities, with more frequent ECG monitoring considered. Routinely monitor ECG and blood potassium and magnesium levels during treatment.</p>'

hcpIsiFull += '<h3 style="color:#000000">Hypocortisolism</h3>'

hcpIsiFull +=
  '<p style="margin-bottom: 0rem;">Recorlev lowers cortisol levels and may lead to hypocortisolism with a potential for life-threatening adrenal insufficiency. Lowering of cortisol levels can cause nausea, vomiting, fatigue, abdominal pain, loss of appetite, and dizziness. Significant lowering of serum cortisol levels may result in adrenal insufficiency that can be manifested by hypotension, abnormal electrolyte levels, and hypoglycemia. Routinely monitor 24-hour urine free cortisol, morning serum or plasma cortisol, and patient’s signs and symptoms for hypocortisolism during treatment.</p>'

hcpIsiFull += '<h3 style="color:#000000">Hypersensitivity Reactions</h3>'

hcpIsiFull +=
  '<p style="margin-bottom: 0rem;">Hypersensitivity to Recorlev has been reported. Anaphylaxis and other hypersensitivity reactions including urticaria have been reported with oral ketoconazole.</p>'

hcpIsiFull +=
  '<h3 style="color:#000000">Risks Related to Decreased Testosterone</h3>'

hcpIsiFull +=
  "<p>Recorlev may lower serum testosterone in men and women. Potential clinical manifestations of decreased testosterone concentrations in men may include gynecomastia, impotence and oligospermia. Potential clinical manifestations of decreased testosterone concentrations in women include decreased libido and mood changes.</p>"

hcpIsiFull += "<h2>ADVERSE REACTIONS</h2>"

hcpIsiFull +=
  "<p>Most common adverse reactions (incidence > 20%) are nausea/vomiting, hypokalemia, hemorrhage/contusion, systemic hypertension, headache, hepatic injury, abnormal uterine bleeding, erythema, fatigue, abdominal pain/dyspepsia, arthritis, upper respiratory infection, myalgia, arrhythmia, back pain, insomnia/sleep disturbances, and peripheral edema.</p>"

hcpIsiFull += "<h2>DRUG INTERACTIONS</h2>"

hcpIsiFull +=
  "<ul> <li>Consult approved product labeling for drugs that are substrates of CYP3A4, P-gp, OCT2, and MATE prior to initiating Recorlev.</li> <li><u>Sensitive CYP3A4 or CYP3A4 and P-gp Substrates</u>: Concomitant use of Recorlev with these substrates is contraindicated or not recommended.</li> <li><u>Atorvastatin</u>: Use lowest atorvastatin dose possible and monitor for adverse reactions for dosages exceeding 20 mg daily.</li> <li><u>Metformin</u>: Monitor glycemia, kidney function, and vitamin B12 and adjust metformin dosage as needed.</li> <li><u>Strong CYP3A4 Inhibitors or Inducers</u>: Avoid use of these drugs 2 weeks before and during Recorlev treatment.</li> <li><u>Gastric Acid Modulators</u>: See Full Prescribing Information for recommendations regarding concomitant use with Recorlev.</li> </ul>"

hcpIsiFull += "<h2>USE IN SPECIFIC POPULATIONS</h2>"

hcpIsiFull +=
  "<p><u>Lactation</u>: Advise not to breastfeed during treatment and for one day after final dose.</p>"

hcpIsiFull +=
  '<p style="color:#00205b;"><strong>For more information, please see the <a href="https://www.recorlev.com/full-prescribing-information.pdf" target="_blank" rel="noreferrer" class="text-underline gtm-pdf" data-gtm-event-label="Full Prescribing Information">Full Prescribing Information</a>, including Boxed Warning, for Recorlev.</strong></p></div>'

export const hcpDetails = { hcpIsiSummary, hcpIsiFull, hcpCookie }
