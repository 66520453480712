import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const HubSpotForm = ({ portalId, formId }) => {
    useEffect(() => {
        // Load the HubSpot script dynamically
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/embed/v2.js';
        script.type = 'text/javascript';
        script.charset = 'utf-8';

        // Append the script to the body
        document.body.appendChild(script);

        // Once the script is loaded, initialize the form
        script.onload = () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: portalId,
                    formId: formId,
                    target: '#hubspot-form-container', // Target the container div
                });
            }
        };

        // Cleanup: remove the script when the component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, [portalId, formId]); // Dependencies ensure the effect runs when portalId or formId changes

    return <div id="hubspot-form-container"></div>; // The form will be rendered inside this container
};

HubSpotForm.propTypes = {
    portalId: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
};

export default HubSpotForm;