import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Navigation from "./template-partials/global-components/navigation"
import Button from "./template-partials/global-components/buttons"
import { isHCP } from "../helpers/helpers"
import Cookies from "js-cookie"

const Header = ({ location, headerMenu, headerButtons, menuLinks }) => {
  const [navOpen, setNavOpen] = useState(false)
  const [currentSite, setCurrentSite] = useState("")
  const [currentSiteLink, setCurrentSiteLink] = useState("")

  const [showBanner, setShowBanner] = useState(false)

  const toggleNav = e => {
    let width = document.body.clientWidth
    if (width <= 1024) {
      e.currentTarget.classList.toggle("isOpen")
      document
        .querySelector(`.main-header__mobile-menu-wrapper`)
        .classList.toggle("isOpen")
      if (navOpen) {
        setNavOpen(false)
      } else {
        setNavOpen(true)
      }
      e.preventDefault()
    } else {
      return true
    }
  }

  let headerLinks, leftColumn, rightColumn, buttonLinks

  useEffect(() => {
    if (isHCP()) {
      setCurrentSite("hcp")
      setCurrentSiteLink("/hcp/")
    } else {
      setCurrentSite("patient")
      setCurrentSiteLink("/")
    }
  }, [currentSite])

  if (currentSite === "hcp") {
    headerLinks = headerMenu[0].menu
    buttonLinks = headerButtons[0].menu
  } else {
    headerLinks = headerMenu[1].menu
    buttonLinks = headerButtons[1].menu
  }

  const allItems = headerLinks.map((item, i) =>
    item.type === "external" ? (
      <li key={i} className={item.class + " headerLink"}>
        <a
          href={item.link}
          className={item.eventClass}
          data-gtm-event-label={item.eventLabel}
          target="_blank"
          rel="noreferrer"
        >
          {item.name}

        </a>
      </li>
    ) : (
      <li key={i} className={item.class + " headerLink"}>
        <Link
          to={item.link}
          className={item.eventClass}
          data-gtm-event-label={item.eventLabel}
        >
          {item.name}
        </Link>
      </li>
    )
  )

  const allHeaderButtons = buttonLinks.map((item, i) =>
    item.type === "external" ? (
      <li key={i}>
        <Button
          type="anchor"
          ButtonLink={item.link}
          ButtonClass={item.class}
          EventLabel={item.eventLabel}
          ButtonText={item.name}
          ButtonIconAfter={"&nbsp;&gt;"}
        ></Button>
      </li>
    ) : (
      <li key={i}>
        <Button
          ButtonLink={item.link}
          ButtonClass={item.class}
          EventLabel={item.eventLabel}
          ButtonText={item.name}
          ButtonIconAfter={"&nbsp;&gt;"}
        ></Button>
      </li>
    )
  )

  const toggleBanner = e => {
    if (!Cookies.get("showBanner")) {
      Cookies.set("showBanner", true, { expires: 1 })
      setShowBanner(false)
    } else {
      setShowBanner(false)
    }
    e.preventDefault()
  }

  const expand = () => {
    const body = document.body
    const container = document.getElementById('isi');

    if (container.classList.contains('open')) {
      body.classList.remove("isi-open")
      container.classList.remove("open")
      container.classList.add("closed")
    } else {
      body.classList.add("isi-open")
      container.classList.add("open")
      container.classList.remove("closed")
    }
  }

  useEffect(() => {
    if (!Cookies.get("showBanner")) {
      setShowBanner(true)
    }
  }, [])

  function prescribingMouseOver() {
    document.getElementById('prescribing-download').style.display = 'flex';
  }
  function prescribingMouseOut() {
    document.getElementById('prescribing-download').style.display = 'none';
  }
  function medicationMouseOver() {
    document.getElementById('medication-download').style.display = 'flex';
  }
  function medicationMouseOut() {
    document.getElementById('medication-download').style.display = 'none';
  }

  return (
    <header>
      <section className="top-bar-nav">
        <div className="row">
          {(() => {
            if (currentSite === "hcp") {
              leftColumn = "small-5 medium-6 large-2 column d-flex align-middle section-label"
              rightColumn = "small-7 medium-6 large-10 column text-right"
            } else {
              leftColumn = "small-4 medium-6 large-2 column d-flex align-middle section-label"
              rightColumn = "small-8 medium-6 large-10 column text-right"
            }
          })()}
          <div className={leftColumn}>
            <ul>
              <li>
                <strong>
                  {currentSite === "hcp"
                    ? "For Healthcare Professionals"
                    : "For Patients"}
                </strong>
              </li>
              <li class="iis-link">
                  <a onClick={expand}>Important Safety Information</a>
              </li>
            </ul>
          </div>
          <div className={rightColumn}>
            <nav>
              <ul>
                <li role="menuitem" aria-haspopup="true" class="has-dropdown" onMouseOver={prescribingMouseOver} onMouseOut={prescribingMouseOut}>
                  <a href="#" aria-haspopup="true">Prescribing Information</a>
                  <ul class="submenu" aria-label="submenu" role="menu" id="prescribing-download">
                    <li tabindex="0">
                      <a data-gtm-event-label="English" class="gtm-sub-nav" href="/full-prescribing-information.pdf" target="_blank">English</a>
                    </li>
                    <li tabindex="1">
                      <a data-gtm-event-label="Spanish" class="gtm-sub-nav" href="/full-prescribing-information-spanish.pdf" target="_blank">Spanish</a>
                    </li>
                  </ul>
                </li>
                <li role="menuitem" aria-haspopup="true" class="has-dropdown" onMouseOver={medicationMouseOver} onMouseOut={medicationMouseOut}>
                  <a href="#" aria-haspopup="true">Medication Guide</a>
                  <ul class="submenu" aria-label="submenu" role="menu" id="medication-download">
                    <li tabindex="0">
                      <a data-gtm-event-label="English" class="gtm-sub-nav" href="/recorlev-medication-guide.pdf" target="_blank">English</a>
                    </li>
                    <li tabindex="1">
                      <a data-gtm-event-label="Spanish" class="gtm-sub-nav" href="/recorlev-medication-guide-spanish.pdf" target="_blank">Spanish</a>
                    </li>
                  </ul>
                </li>
                {allItems}
              </ul>
            </nav>
          </div>
        </div>
      </section>

      {currentSite === "hcp" && (
        <>
          {showBanner && (
            <section
              className="logics-banner"
              style={{
                color: `white`,
                backgroundColor: `#7f278f`,
                paddingTop: `1rem`,
                paddingBottom: `1rem`,
              }}
            >
              <div className="row">
                <div className="columns">
                  <div className="row" style={{ display: `none` }}>
                    <div className="column small-12">
                      <p
                        className="cta-bloc"
                        style={{ marginBottom: `0px`, lineHeight: `1.4` }}
                      >
                        <strong>
                          The extended evaluation results of the SONICS study
                          and the LOGICS clinical study for Recorlev have been
                          published.
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="columns small-6 medium-shink">
                      <p style={{ marginBottom: `0px`, lineHeight: `1.4` }}>
                        <strong>
                          <span
                            className="cta-bloc"
                            style={{ display: `inline-block` }}
                          >
                            <a
                              href="https://doi.org/10.1530/EJE-22-0506"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: `white` }}
                            >
                              <span style={{ textDecoration: `underline` }}>
                                Read SONICS extended{" "}
                                <br className="hide-for-large" />
                                evaluation results
                              </span>{" "}
                              &gt;
                            </a>
                          </span>
                        </strong>
                      </p>
                    </div>
                    <div className="columns small-6 medium-shink">
                      <p style={{ marginBottom: `0px`, lineHeight: `1.4` }}>
                        <strong>
                          <span
                            className="cta-bloc"
                            style={{ display: `inline-block` }}
                          >
                            <a
                              href="https://link.springer.com/article/10.1007/s11102-022-01263-7"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: `white` }}
                            >
                              <span style={{ textDecoration: `underline` }}>
                                Read LOGICS results
                              </span>{" "}
                              &gt;
                            </a>
                          </span>
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="columns shrink">
                  <button
                    className="banner-close"
                    onClick={e => toggleBanner(e)}
                    style={{
                      color: `white`,
                      fontSize: `2.2rem`,
                      fontWeight: `700`,
                      transform: `rotate(45deg)`,
                      cursor: `pointer`,
                    }}
                  >
                    <span className="visually-hidden">close banner</span>+
                  </button>
                </div>
              </div>
            </section>
          )}
        </>
      )}

      <section className="logo-bar-nav lg_py-1">
        <div className="row small-collapse large-uncollapse top-buttons">
          <div className="info-links small-12 medium-12 column text-center large-text-right">
            <nav className="d-inline-block">
              <ul className="button-list">{allHeaderButtons}</ul>
            </nav>
          </div>
        </div>

        <div className="row logo-bar-nav-wrapper large-uncollapse align-bottom">
          <div className="small-6 large-3 column d-flex flex-dir-column align-center">
            <Link
              to={currentSiteLink}
              className="gtm-header"
              data-gtm-event-label="Recorlev Logo"
            >
              <StaticImage
                id="logo"
                src="../images/recorlev-logo.png"
                alt="Links to Homepage of Recorlev Patient Website"
                placeholder="blurred"
                layout="constrained"
                width={261}
              />
            </Link>
          </div>
          <div className="small-6 column hide-for-large text-right">
            <button
              className="main-nav-toggle hide-for-large"
              onClick={e => toggleNav(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span className="visually-hidden">toggle mobile menu</span>
            </button>
          </div>
          <div className="small-12 large-9 column mobile-menu-container">
            <div className="main-header__mobile-menu-wrapper">
              {/* <div className="row small-collapse large-uncollapse">
                <div className="info-links small-12 medium-12 column text-center large-text-right">
                  <nav className="d-inline-block">
                    <ul className="button-list">{allHeaderButtons}</ul>
                  </nav>
                </div>
              </div> */}
              <div className="row small-collapse large-uncollapse main-navigation">
                <div className="small-12 medium-12 column">
                  <Navigation location={location} menuLinks={menuLinks} />
                </div>
                <div className="info-links small-12 medium-12 column text-center large-text-right hide-for-large">
                  <nav className="d-inline-block">
                    <ul className="button-list">
                      {allItems}
                      {allHeaderButtons}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
