import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Section from "../../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../../components/template-partials/global-components/interior-page-header"
import Button from "../../../components/template-partials/global-components/buttons"
import Card from "../../../components/template-partials/global-components/cards"

const ToolsResources = () => (
  <Layout>
    <Seo
      title="Tool and Resources | RECORLEV® (levoketoconazole)"
      description="Find downloadable Recorlev resources for your practice."
      ogdescription="Find downloadable Recorlev resources for your practice. See Important Safety Information, including BOXED WARNING."
      siteType="hcp"
    />
    <InteriorPageHeader alt="Tools and Resources">
      <h1>Resources for your practice</h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div className="row" style={{ display: "none" }}>
        <div className="small-12 column">
          <h2>Downloadable resources for you and your patients</h2>
        </div>
        <div className="small-12 medium-5 column">
          <Card
            CardClass={"__media-object d-flex --resources-pdf"}
            CardImgSrc={
              <StaticImage
                src="../../../images/getting-started-brochure.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={229}
              />
            }
            CardTitle={<h4>Getting Started Brochure</h4>}
            CardText={
              <>
                <p>
                  A brochure that outlines everything you need to know to help
                  your patients get started on treatment with Recorlev
                  <sup>®</sup>.
                </p>
              </>
            }
            CardButton={
              <Button
                ButtonLink={"/Recorlev-getting-started-brochure.pdf"}
                ButtonClass={"--download gtm-pdf"}
                ButtonText={"Download PDF"}
                ButtonIconBefore={"Icondownload"}
                EventLabel={"Getting Started Brochure"}
                type="anchor"
              ></Button>
            }
          ></Card>
        </div>
        {/* <div className="small-12 medium-6 medium-offset-1 column">
          <Card
            CardClass={"__media-object d-flex --resources-pdf"}
            CardImgSrc={
              <StaticImage
                src="../../../images/ehr-guide.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={229}
              />
            }
            CardTitle={<h4>EHR Support for your Practice</h4>}
            CardText={
              <>
                <p>
                  Step-by-step and general instructions on how to request a
                  report based on the ICD-10 diagnosis codes associated with
                  Cushing’s syndrome.
                </p>
              </>
            }
            CardButton={
              <Button
                ButtonLink={"/Recorlev-ehr-guide.pdf"}
                ButtonClass={"--download gtm-pdf"}
                ButtonText={"Download PDF"}
                ButtonIconBefore={"Icondownload"}
                EventLabel={"Prescription Start Form"}
                type="anchor"
              ></Button>
            }
          ></Card>
        </div> */}
      </div>

      <div className="row mt-3 md_mt-3">
        <div className="small-12 column">
          <h3>Patient Brochures</h3>
        </div>
        <div className="small-12 medium-6 column">
          <Card
            CardClass={"__media-object d-flex --resources-pdf"}
            CardImgSrc={
              <StaticImage
                src="../../../images/recorlev-digital-patient-brochure-english.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={229}
              />
            }
            CardTitle={<h4>Recorlev Patient Brochure - English</h4>}
            CardText={
              <></>
            }
            CardButton={
              <Button
                ButtonLink={"/recorlev-digital-patient-brochure-english.pdf"}
                ButtonClass={"--download gtm-pdf"}
                ButtonText={"Download English PDF"}
                ButtonIconBefore={"Icondownload"}
                EventLabel={"Recorlev Patient Brochure"}
                type="anchor"
              ></Button>
            }
          ></Card>
        </div>
        <div className="small-12 medium-6 column">
          <Card
            CardClass={"__media-object d-flex --resources-pdf"}
            CardImgSrc={
              <StaticImage
                src="../../../images/recorlev-digital-patient-brochure-spanish.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={229}
              />
            }
            CardTitle={<h4>Recorlev Patient Brochure - Spanish</h4>}
            CardText={<></>}
            CardButton={
              <Button
                ButtonLink={"/recorlev-digital-patient-brochure-spanish.pdf"}
                ButtonClass={"--download gtm-pdf"}
                ButtonText={"Download Spanish PDF"}
                ButtonIconBefore={"Icondownload"}
                EventLabel={"Recorlev Patient Brochure"}
                type="anchor"
              ></Button>
            }
          ></Card>
        </div>
      </div>

      <div className="row mt-3 md_mt-3">
        <div className="small-12 column">
          <h3>Prescription and reimbursement form</h3>
        </div>
        <div className="small-12 medium-5 column">
          <Card
            CardClass={"__media-object d-flex --resources-pdf"}
            CardImgSrc={
              <StaticImage
                src="../../../images/prescription-start-form.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={229}
              />
            }
            CardTitle={<h4>Prescription Start Form</h4>}
            CardText={
              <>
                <p>
                  The Prescription Start Form includes everything you need to
                  send to the specialty pharmacy.
                </p>
              </>
            }
            CardButton={
              <Button
                ButtonLink={"/Recorlev-prescription-start-form.pdf"}
                ButtonClass={"--download gtm-pdf"}
                ButtonText={"Download PDF"}
                ButtonIconBefore={"Icondownload"}
                EventLabel={"Prescription Start Form"}
                type="anchor"
              ></Button>
            }
          ></Card>
        </div>
        <div className="small-12 medium-6 medium-offset-1 column">
          <Card
            CardClass={"__media-object d-flex --resources-pdf"}
            CardImgSrc={
              <StaticImage
                src="../../../images/letterofmedical-necessity-template.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={229}
              />
            }
            CardTitle={<h4>Letter of Medical Necessity Example</h4>}
            CardText={<></>}
            CardButton={
              <Button
                ButtonLink={"/Recorlev-letter-of-medical-necessity.pdf"}
                ButtonClass={"--download gtm-pdf"}
                ButtonText={"Download PDF"}
                ButtonIconBefore={"Icondownload"}
                EventLabel={"Letter of Medical Necessity Template"}
                type="anchor"
              ></Button>
            }
          ></Card>
        </div>
      </div>

      <div className="row md_mt-2">
        <div className="small-12 medium-5 column">
          <Card
            CardClass={"__media-object d-flex --resources-pdf"}
            CardImgSrc={
              <StaticImage
                src="../../../images/appeal-letter-template.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={229}
              />
            }
            CardTitle={<h4>Appeal Letter Example</h4>}
            CardText={<></>}
            CardButton={
              <Button
                ButtonLink={"/Recorlev-appeal-letter.pdf"}
                ButtonClass={"--download gtm-pdf"}
                ButtonText={"Download PDF"}
                ButtonIconBefore={"Icondownload"}
                EventLabel={"Appeal Letter Template"}
                type="anchor"
              ></Button>
            }
          ></Card>
        </div>
        <div className="small-12 medium-6 medium-offset-1 column">
          <Card
            CardClass={"__media-object d-flex --resources-pdf"}
            CardImgSrc={
              <StaticImage
                src="../../../images/formulary-exception-letter-template.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={229}
              />
            }
            CardTitle={<h4>Formulary Exception Letter Example</h4>}
            CardText={<></>}
            CardButton={
              <Button
                ButtonLink={"/Recorlev-appeal-letter-formulary.pdf"}
                ButtonClass={"--download gtm-pdf"}
                ButtonText={"Download PDF"}
                ButtonIconBefore={"Icondownload"}
                EventLabel={"Formulary Exception Letter Template"}
                type="anchor"
              ></Button>
            }
          ></Card>
        </div>
      </div>

      <div className="row py-1 lg_py-3">
        <div className="column text-center">
          <Button
            ButtonLink={"/hcp/support/connect-with-xeris-support/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={"Request Support"}
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"Request Support"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default ToolsResources
